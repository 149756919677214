<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-row no-gutters>
                <v-col cols="5" lg="5" md="12" xs="12" class="px-1 pb-0 pt-3">
                    <v-row no-gutters>
                        <v-col cols="5" lg="5" md="3" xs="3">
                            <v-select
                                    :items="searchFields"
                                    :placeholder="$t('message.searchColumn')"
                                    autocomplete="off"
                                    clearable
                                    prepend-icon="search"
                                    dense
                                    hide-details
                                    solo
                                    v-model="searchField"
                                    @click:clear="resetSearch"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="4" lg="4" md="3" xs="3" class="pl-2">
                            <v-text-field
                                    :placeholder="$t('message.searchTerm')"
                                    autocomplete="off"
                                    class="force-text-left"
                                    clearable
                                    dense
                                    hide-details
                                    solo
                                    v-model="searchTerm"
                                    @keyup.enter="searchPackingLists"
                                    @click:clear="resetSearch"
                            >
                            </v-text-field>
                        </v-col>
                        <v-col cols="3" lg="3" md="3" xs="3" class="pl-2">
                            <v-row no-gutters>
                                <v-btn
                                        :loading="loading.search"
                                        class="col-6"
                                        @click="searchPackingLists"
                                >{{ $t('message.search') }}</v-btn>
                                <v-btn
                                        :loading="loading.reset"
                                        class="col-6"
                                        @click="resetSearch"
                                >{{ $t('message.reset') }}</v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-sheet>
        <v-overlay
                :value="loading.packinglists"
                absolute
                opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                    ></v-progress-circular>
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
                :footer-props="{
                    itemsPerPageOptions: [10,25,50,-1],
                    showFirstLastPage: true
                }"
                :headers="headers"
                :hide-default-header="hideDefaultHeader"
                :hide-default-footer="hideDefaultFooter"
                :items="PackingLists"
                :options.sync="tableOptions"
                :server-items-length="totalPackingLists"
                calculate-widths
                class="mt-3 appic-table-light specification-table"
                dense
                id="packingListTable"
                item-key="Pldo.id"
        >
<!--            <template v-slot:header="{ props: { headers } }">-->
<!--                <tr>-->
<!--                    <th class="grey lighten-3 pa-1 text-no-wrap width-1-pct">{{ headers[0].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 width-1-pct">{{ headers[1].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 text-no-wrap width-1-pct">{{ headers[2].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 width-1-pct">{{ headers[3].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 width-1-pct">{{ headers[4].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 width-1-pct">{{ headers[5].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 width-1-pct">{{ headers[6].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1">{{ headers[7].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 text-center width-6-pct">{{ headers[8].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 text-center width-6-pct">{{ headers[9].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 text-center width-6-pct">{{ headers[10].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 text-center width-8-pct">{{ headers[11].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 text-center width-8-pct">{{ headers[12].text }}</th>-->
<!--                    <th class="grey lighten-3 pa-1 width-1-pct">{{ headers[13].text }}</th>-->
<!--                </tr>-->
<!--            </template>-->
            <template v-slot:item.Pldo.title="{ item }">
                <span class="font-weight-bold">{{ item.Pldo.title }}</span>
            </template>
            <template v-slot:item.Pldo.pldodate="{ item }">
                {{ item.Pldo.pldodate != '0000-00-00' ? formatDate(item.Pldo.pldodate) : '' }}
            </template>
            <template v-slot:item.Pldo.Customer.title="{ item }">
                <div class="text-no-wrap">{{ item.Pldo.Customer.otsname != '' ? item.Pldo.Customer.otsname : item.Pldo.Customer.title }}</div>
            </template>
            <template v-slot:item.Pldo.Contract.title="{ item }">
                <span class="text-no-wrap">{{ item.Pldo.Contract.version != null ? [item.Pldo.Contract.title, item.Pldo.Contract.revision_no , item.Pldo.Contract.partial_no].filter(Boolean).join('-') : item.Pldo.Contract.title }}</span>
            </template>
            <template v-slot:item.Pldo.Contract.etddate="{ item }">
                {{ item.Pldo.Contract.etddate != '0000-00-00' ? formatDate(item.Pldo.Contract.etddate) : '' }}
            </template>
            <template v-slot:item.Pldo.Contract.etadate="{ item }">
                {{ item.Pldo.Contract.etadate != '0000-00-00' ? formatDate(item.Pldo.Contract.etadate) : '' }}
            </template>
            <template v-slot:item.Pldo.Contract.containersize_id="{ item }">
                <div class="text-no-wrap">
                    <span v-if=" [1,2,3,5].includes(item.Pldo.Contract.containersize_id) ">{{ item.Pldo.Contract.containercount }}</span>
                    <span v-if=" [1,2,3,5].includes(item.Pldo.Contract.containersize_id) "> X </span>
                    <span>{{ getContainerSize(item.Pldo.Contract.containersize_id) }}</span>
                </div>
            </template>
            <template v-slot:item.Pldo.Contract.shipmentstatus_id="{ item }">
                <span class="text-no-wrap" v-if="item.Pldo.Contract.shipmentstatus_id == 6">
                    <v-icon x-small color="grey" class="mr-1">fas fa-ship</v-icon><span :class="item.Pldo.Contract.shipmentstatus_text && item.Pldo.Contract.shipmentstatus_text.length > 10 ? 'font-xs' : ''">{{ getShipmentStatus(item.Pldo.Contract.shipmentstatus_id, item.Pldo.Contract.shipmentstatus_text) }}</span>
                </span>
            </template>
            <template v-slot:item.Pldo.Total.bundles="{ item }">
                <div>{{ item.Pldo.version != null ? item.Pldo.Total.bundles : $t('message.na') }}</div>
            </template>
            <template v-slot:item.Pldo.Total.pieces="{ item }">
                <div>{{ item.Pldo.version != null ? item.Pldo.Total.pieces : $t('message.na') }}</div>
            </template>
            <template v-slot:item.Pldo.Total.volume="{ item }">
                <span class="text-no-wrap">{{ item.Pldo.version != null ? formatThisNumber(item.Pldo.Total.volume,'0,0.00') : $t('message.na') }}</span>
            </template>
            <template v-slot:item.Pldo.grossweight="{ item }">
                <span class="text-no-wrap">{{ item.Pldo.version != null ? formatThisNumber(item.Pldo.grossweight,uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.gwmeasurement_id).Measurement.formatPrecision) + ' ' + uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.gwmeasurement_id).Measurement.title : item.Pldo.grossweight.toUpperCase() }}</span>
            </template>
            <template v-slot:item.Pldo.netweight="{ item }">
                <span class="text-no-wrap">{{ item.Pldo.version != null ? formatThisNumber(item.Pldo.netweight,uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.nwmeasurement_id).Measurement.formatPrecision) + ' ' + uofmWeights.find(uofm=>uofm.Measurement.id == item.Pldo.nwmeasurement_id).Measurement.title : item.Pldo.netweight.toUpperCase() }}</span>
            </template>
            <template v-slot:item.Pldo.id="{ item }">
                <v-menu>
                    <template v-slot:activator="{ on: menu }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.moreActions') }}</span>
                        </v-tooltip>
                    </template>
                    <v-list dense>
                        <v-list-item class="font-sm" @click="updatePackingList(item.Pldo.id)"><v-icon color="black lighten-1" small class="mr-1">edit</v-icon>{{ $t('message.updatePackingList') }}</v-list-item>
                        <v-list-item class="font-sm" @click="cancelPackingList(item.Pldo.id, item.Pldo.title)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.cancelPackingList') }}</v-list-item>
                        <v-list-item class="font-sm" @click="viewPdf(item.Pldo.id, item.Pldo.version)"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.pdf') }}</v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        ></SimpleAlert>
    </div>
</template>

<script>
    import {formatDate} from "Helpers/helpers";
    import SimpleAlert from "./SimpleAlert";
    import {numberFormat} from "../../helpers/helpers";
    import {mapGetters, mapActions} from "vuex";
    import {mapFields, mapMultiRowFields} from "vuex-map-fields";
    import { v4 as uuidv4 } from 'uuid';

    export default {
        name: "PackingListsListing",
        components: {SimpleAlert},
        data() {
            return {
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                filterOptions: {
                    status: {
                        Status: {
                            id: 1,
                            title: 'open'
                        }
                    }
                },
                hideDefaultHeader: false,
                hideDefaultFooter: false,
                loading: {
                    filter: {
                        buyers: false
                    },
                    search: false,
                    packinglists: false
                },
                searchField: 'Pldo.Contract.title', //default search field
                searchTerm: null,
                tableOptions: {},
            }
        },
        computed: {
            ...mapMultiRowFields('packinglist',{
                PackingLists: 'current.PackingLists'
            }),
            ...mapFields('packinglist',{
                totalPackingLists: 'current.totalPackingLists'
            }),
            ...mapFields('runtime',{
                openedTabs: 'openedTabs'
            }),
            ...mapGetters([
                'containerSizes',
                'currencies',
                'shipmentStatuses',
                'uofmDimensions',
                'uofmQty',
                'uofmVolumes',
                'uofmWeights'
            ]),
            console: () => console,
            formatDate: () => formatDate,
            headers() {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.actions'),
                        value: 'Pldo.id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.plNumber'),
                        value: 'Pldo.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.plDate'),
                        value: 'Pldo.pldodate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 3,
                        text: this.$t('message.buyer'),
                        value: 'Pldo.Customer.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
                    {
                        id: 4,
                        text: this.$t('message.contract'),
                        value: 'Pldo.Contract.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: true,
                        sortable: false
                    },
                    {
                        id: 5,
                        text: this.$t('message.vessel'),
                        value: 'Pldo.Contract.shipmentstatus_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 6,
                        text: this.$t('message.etd'),
                        value: 'Pldo.Contract.etddate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 7,
                        text: this.$t('message.eta'),
                        value: 'Pldo.Contract.etadate',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 8,
                        text: this.$t('message.qty'),
                        value: 'Pldo.Contract.containersize_id',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 9,
                        text: this.$t('message.packages'),
                        value: 'Pldo.Total.bundles',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 10,
                        text: this.$t('message.pieces'),
                        value: 'Pldo.Total.pieces',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 11,
                        text: this.$t('message.volume'),
                        value: 'Pldo.Total.volume',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 12,
                        text: this.$t('message.grossWeight'),
                        value: 'Pldo.grossweight',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 13,
                        text: this.$t('message.netWeight'),
                        value: 'Pldo.netweight',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    }
                ]
                return headers
            },
            searchFields() {
                return this.headers.filter((header)=>header.searchable === true)
            },
            years() {
                let years = []
                let dt = new Date()
                let startYear = dt.getFullYear()
                let endYear = 2017
                for(let i = startYear; i >= endYear; i--){
                    years.push(i)
                }
                return years
            }
        },
        methods: {
            ...mapActions('packinglist',{
                cancelPackingListById: 'cancelPackingListById',
                getAllPackingLists: 'getAllPackingLists',
                searchAllPackingLists: 'searchAllPackingLists'
            }),
            async cancelPackingList (val, title) {
                if(await this.$root.$confirm(this.$t('message.cancelPackingList') + ' ' + title, this.$t('message.confirmations.continuePackingListCancelAction'), {color: 'orange'})){
                    this.cancelPackingListById(val).then((status) => {
                        if(status == 'done'){
                            this.$toast.success(this.$t('message.successes.packingListDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loadPackingLists()
                        } else {
                            this.$toast.error(this.$t('message.errors.packingListNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(()=>{
                        this.$toast.error(this.$t('message.errors.packingListNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(()=>{

                    })
                }
            },
            dialogClosed () {
                this.dialogs.error = false;
                this.dialogs.error_message = ''
            },
            formatThisNumber(value,format){
                return numberFormat(value,format)
            },
            getContainerSize (val) {
                let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == val)
                if(container){
                    return container.Containersize.title
                } else {
                    return null
                }
            },
            getShipmentStatus (id, text) {
                if(id) {
                    let status = this.shipmentStatuses.find(d => d.Shipmentstatus.id == id)
                    if(status?.Shipmentstatus?.hastext == 1){
                        if(id == 1){
                            if(parseInt(text) == text){
                                return status?.Shipmentstatus?.title + ': ' + text
                            } else {
                                return text
                            }
                        } else {
                            return text
                        }
                    } else {
                        return status?.Shipmentstatus?.title
                    }
                }
                return null
            },
            loadPackingLists () {
                this.loading.packinglists = true;
                let payload = {
                    tableOptions: this.tableOptions,
                    filterOptions: this.filterOptions
                }
                this.getAllPackingLists(payload)
                    .then(()=>{
                        this.loading.packinglists = false;
                    })
                    .catch(()=>{
                        this.loading.packinglists = false;
                    })
                    .finally(()=>{
                        this.loading.packinglists = false;
                    })
            },
            resetSearch () {
                this.hideDefaultFooter = false
                this.loading.packinglists = false
                this.searchField = 'Pldo.Contract.title'; //default search fields
                this.searchTerm = null;
                this.tableOptions.itemsPerPage = 10
                this.filterOptions = {};
            },
            searchPackingLists () {
                if(this.searchField == null || this.searchTerm == null){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.errors.noSearchColumnAndSearchTerm')
                } else {
                    //TODO search packing lists
                    this.filterOptions = {}
                    this.hideDefaultFooter = true
                    this.loading.packinglists = true;
                    this.loading.search = true;
                    let payload = {
                        tableOptions: this.tableOptions,
                        search: {
                            field: this.searchField,
                            value: this.searchTerm
                        }
                    }
                    this.searchAllPackingLists(payload)
                        .then(() => {
                            this.loading.search = false;
                            this.loading.packinglists = false;
                        })
                        .catch(()=>{
                            this.loading.search = false;
                            this.loading.packinglists = false;
                        })
                        .finally(()=>{
                            this.loading.search = false;
                            this.loading.packinglists = false;
                        })
                }
            },
            updatePackingList (val) {
                let tab = window.open('/v1/packinglists/update/' + val,'_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
                // router.push({name: 'update_packinglist', params: { packingListId : val}}).catch(err => {})
            },
            viewPdf (packingListId, version) {
                if(version == null){
                    this.dialogs.error = true
                    this.dialogs.error_message = this.$t('message.packingListNotConverted')
                } else {
                    let pl = this.PackingLists.find( pl => pl.Pldo.id == packingListId)
                    let document = encodeURIComponent('PL ' + (pl.Pldo.Contract.version != null ? [pl.Pldo.Contract.title, pl.Pldo.Contract.revision_no , pl.Pldo.Contract.partial_no].filter(Boolean).join('-') : pl.Pldo.Contract.title))
                    let user = JSON.parse(localStorage.getItem('user'))
                    let tab = window.open(
                        process.env.VUE_APP_PDF_SERVER
                        + '/api/render/'
                        + document
                        + '?url='
                        + process.env.VUE_APP_BASE_URL
                        + '/v1/packinglists/print/'
                        + packingListId
                        + '/'
                        + uuidv4()
                        + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0&pdf.landscape=true'
                        , "_blank");
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                }
            },
        },
        watch: {
            filterOptions: {
                handler(){
                    if(!this.loading.search) {
                        this.loadPackingLists()
                    }
                },
                deep: true
            },
            tableOptions: {
                handler(){
                    this.loadPackingLists()
                },
                deep: true
            },
        },
        created(){
            let dt = new Date()
            let defaultYear = dt.getFullYear() - 1
            this.loadPackingLists()
        }
    }
</script>

<style>
.font-xs {
    font-size: 0.60rem;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 100px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>